import { EmptyState, EmptyStateIcon, RfhSelect, RfhTheme, RfhTypography, TextField } from '@rfh/ui';
import { SelectItemProps } from '@rfh/ui/components/Inputs/RfhSelect';
import LoadingIconText from '@rfh/ui/components/LoadingStates/LoadingIconText';
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import {
    FustExportService, FustReportModel,
    UsageSelectionEnum, ProductSelectionEnum,
    tableDataVariant, tableDataLabelVariant, FustService, LanguageService
} from './../../common';
import { defaultLanguage } from './../../i18n';
import styles from './fustlist.module.css';
import { ReCAPTCHAComponent } from '../reCAPTCHA';

export const FustList = () => {
    const reCaptchaRef = useRef<any>();
    let params = useParams();
    let { t, i18n } = useTranslation();
    let navigate = useNavigate();

    //Algemene states
    const [fustList, setFustList] = useState<FustReportModel[]>([]);
    const [defaultFustList, setDefaultFustList] = useState<FustReportModel[]>([]);
    const [fustTypeList, setFustTypeList] = useState<SelectItemProps[]>([]);
    const [fustListError, setFustListError] = useState(false);
    const [fustTypeError, setFustTypeError] = useState(false);
    const [fustListLoaded, setFustListLoaded] = useState(false);

    //Alle Search en Filter states
    const [generalSearchField, setGeneralSearchField] = useState(localStorage.getItem('generalSearchField') ? localStorage.getItem('generalSearchField') : '');
    const [fustSizeTextField, setFustSizeSearchField] = useState(localStorage.getItem('fustSizeTextField') ? localStorage.getItem('fustSizeTextField') : '');
    const [amountOfHolesSearchField, setAmountOfHolesSearchField] = useState(localStorage.getItem('amountOfHolesSearchField') ? localStorage.getItem('amountOfHolesSearchField') : '');
    const [selectedFustType, setSelectedFustType] = useState(localStorage.getItem('selectedFustType') ? localStorage.getItem('selectedFustType') : '');
    const [usageRadioButton, setUsageRadioButton] = useState<UsageSelectionEnum>(
        localStorage.getItem('usageRadioButton') != null ?
            UsageSelectionEnum[UsageSelectionEnum[localStorage.getItem('usageRadioButton')] as keyof typeof UsageSelectionEnum] : UsageSelectionEnum.NVT
    );
    const [productRadioButton, setProductRadioButton] = useState<ProductSelectionEnum>(
        localStorage.getItem('usageRadioButton') != null ?
            ProductSelectionEnum[ProductSelectionEnum[localStorage.getItem('productRadioButton')] as keyof typeof UsageSelectionEnum] : ProductSelectionEnum.Alle
    );

    //De UseEffect voor veranderen van de filter states. 
    //Er is een dependency op elke variabele zodat dit altijd uitgevoerd wordt bij een wijziging.
    useEffect(() => {
        if (fustListLoaded) {
            FilterFustList(defaultFustList);
        }
    }, [generalSearchField, fustSizeTextField, amountOfHolesSearchField, usageRadioButton, productRadioButton, selectedFustType]);

    //Deze UseEffect wordt gebruikt om de data op te halen en in te laden. 
    //Voor de rest heeft die geen dependency op een State waardoor dit alleen OnLoad uitgevoerd wordt.
    useEffect(() => {
        if (params.lang) {
            i18n.changeLanguage(params.lang);
        } else {
            i18n.changeLanguage(defaultLanguage);
        }
        const getFustList = async (fustService: FustService) => {
            try {
                setFustListError(false);
                const value = await reCaptchaRef.current.executeAsync()
                const fustList = await fustService.getAll(value);
                if (params.lang && params.lang != defaultLanguage) {
                    const value = await reCaptchaRef.current.executeAsync()
                    const translatedFustList = await getTranslatedFustDescription(fustList, value);
                    setDefaultFustList(translatedFustList);
                    setFustList(translatedFustList);
                    FilterFustList(translatedFustList);
                } else {
                    setDefaultFustList(fustList);
                    setFustList(fustList);
                    FilterFustList(fustList);
                }
                setFustListLoaded(true);
            } catch {
                setFustListError(true);
            }
        };
        const getFustTypes = async (fustService: FustService) => {
            try {
                console.log('taal: ',i18n.language);
                setFustTypeError(false);
                const value = await reCaptchaRef.current.executeAsync()
                const fustTypes = await fustService.getTypes(i18n.language,value);
                setFustTypeList(fustTypes);
            } catch {
                setFustTypeError(true);
            }
        };
        const getTranslatedFustDescription = async (fustList: FustReportModel[], token: string): Promise<FustReportModel[]> => {
            try {
                const languageService = new LanguageService();
                var newFustList: FustReportModel[] = [];
                if (params.lang) {
                    const translatedFustList = await languageService.get(params.lang, token);
                    translatedFustList.forEach(element => {
                        var result = fustList.find(x => x.FustCode === element.FustCode)
                        if (result) {
                            result.FustOmschrijving = element.Omschrijving;
                            newFustList.push(result);
                        }
                    });
                    return fustList.map(obj => newFustList.find(x => x.FustCode === obj.FustCode) || obj);
                }
                return fustList;
            } catch {
                return fustList;
            }
        };
        const getData = async () => {
            const fustService = new FustService();
            await getFustTypes(fustService);
            await getFustList(fustService);
        };
        getData();
    }, []);

    const ClearSearchInputs = () => {
        setGeneralSearchField('');
        setFustSizeSearchField('');
        setAmountOfHolesSearchField('');
        setUsageRadioButton(UsageSelectionEnum.NVT);
        setProductRadioButton(ProductSelectionEnum.Alle);
        setSelectedFustType('');
        localStorage.clear();
    }

    const FilterFustList = (filterableFustList: FustReportModel[]) => {
        let newFustList = filterableFustList.filter(fust =>
            fust.FustOmschrijving.toLowerCase().match(generalSearchField ? generalSearchField.toLowerCase() : '') || fust.FustCode.toString().match(generalSearchField ? generalSearchField : '')
        );
        if (generalSearchField || generalSearchField == '') {
            localStorage.setItem('generalSearchField', generalSearchField ? generalSearchField : '');
        }
        if (fustSizeTextField) {
            newFustList = newFustList.filter(fust =>
                fust.MinPotmaat <= fustSizeTextField && fustSizeTextField <= fust.MaxPotmaat);
            localStorage.setItem('fustSizeTextField', fustSizeTextField ? fustSizeTextField : '');
        } else if (fustSizeTextField == '') {
            localStorage.setItem('fustSizeTextField', fustSizeTextField ? fustSizeTextField : '');
        }
        if (amountOfHolesSearchField) {
            newFustList = newFustList.filter(fust =>
                fust.AantalGaten.match(amountOfHolesSearchField));
            localStorage.setItem('amountOfHolesSearchField', amountOfHolesSearchField ? amountOfHolesSearchField : '');
        } else if (amountOfHolesSearchField == '') {
            localStorage.setItem('amountOfHolesSearchField', amountOfHolesSearchField ? amountOfHolesSearchField : '');
        }
        if (usageRadioButton != UsageSelectionEnum.NVT && usageRadioButton) {
            newFustList = newFustList.filter(fust =>
                fust.Gebruik.toLowerCase() === UsageSelectionEnum[usageRadioButton].toLowerCase());
            localStorage.setItem('usageRadioButton', usageRadioButton ? usageRadioButton.toString() : UsageSelectionEnum.NVT.toString());
        } else if (usageRadioButton == UsageSelectionEnum.NVT) {
            localStorage.setItem('usageRadioButton', UsageSelectionEnum.NVT.toString());
        }
        if (productRadioButton != ProductSelectionEnum.Alle && productRadioButton) {
            newFustList = newFustList.filter(fust =>
                parseInt(fust.Product) === productRadioButton);
            localStorage.setItem('productRadioButton', productRadioButton ? productRadioButton.toString() : ProductSelectionEnum.Alle.toString());
        } else if (productRadioButton == ProductSelectionEnum.Alle) {
            localStorage.setItem('productRadioButton', ProductSelectionEnum.Alle.toString());
        }
        if (selectedFustType) {
            newFustList = newFustList.filter(fust =>
                fust.Soort.toLowerCase() === selectedFustType?.toLowerCase());
            localStorage.setItem('selectedFustType', selectedFustType ? selectedFustType : '');
        } else if (selectedFustType == '') {
            localStorage.setItem('selectedFustType', selectedFustType ? selectedFustType : '');
        }
        setFustList(newFustList);
    };

    const renderRows = (data: FustReportModel[]) => {
        return data.map((x) => {
            return (
                <tr className={styles.dataRow} key={x.FustCode} onClick={() => handleRowClick(x.FustCode)}>
                    <td><RfhTypography variant={tableDataVariant}>{x.FustCode}</RfhTypography></td>
                    <td><RfhTypography variant={tableDataVariant}>{x.FustCode.toString() == x.FustCode.toString() ? x.FustOmschrijving : x.FustCode.toString()}</RfhTypography></td>
                    <td><RfhTypography variant={tableDataVariant}>{x.Lengte}</RfhTypography></td>
                    <td><RfhTypography variant={tableDataVariant}>{x.Breedte}</RfhTypography></td>
                    <td><RfhTypography variant={tableDataVariant}>{x.Hoogte}</RfhTypography></td>
                    <td><RfhTypography variant={tableDataVariant}>{getUsageLabel(x.Gebruik)}</RfhTypography></td>
                    <td><RfhTypography variant={tableDataVariant}>{x.AantalGaten}</RfhTypography></td>
                    <td><RfhTypography variant={tableDataVariant}>{x.MinPotmaat}</RfhTypography></td>
                    <td><RfhTypography variant={tableDataVariant}>{x.MaxPotmaat}</RfhTypography></td>
                </tr>);
        });
    }


    const handleRowClick = (fustcode: number) => {
        navigate(`/${fustcode}/${params.lang ? params.lang : 'nl'}`);
    };

    const getUsageLabel = (usage: string) => {
        switch (usage) {
            case 'Eenmalig':
                return t('useDataSingleUse');
            case 'Meermalig':
                return t('useDataMultipleUse');
            case 'Beiden':
                return t('useDataBoth');
            default:
                return '';
        }
    };

    const handleExportCsvClick = async () => {
        const fustExportService = new FustExportService();
        const value = await reCaptchaRef.current.executeAsync()
        const fustExportList = await fustExportService.get(params.lang ? params.lang : 'nl', value);
        const fustCodeList = fustList.map(x => x.FustCode);
        const filteredFustExportList = fustExportList.filter(x => fustCodeList.includes(x.FustCode))
        fustExportService.exportCsv(filteredFustExportList);
    };

    const handleExportExcelClick = async () => {
        const fustExportService = new FustExportService();
        const value = await reCaptchaRef.current.executeAsync()
        const fustExportList = await fustExportService.get(params.lang ? params.lang : 'nl', value);
        const fustCodeList = fustList.map(x => x.FustCode);
        const filteredFustExportList = fustExportList.filter(x => fustCodeList.includes(x.FustCode))
        fustExportService.exportToXlxs(filteredFustExportList, [
            t('table.fustCode'),
            t('table.description'),
            t('table.length'),
            t('table.width'),
            t('table.height'),
            t('table.use'),
            t('table.potSizeMin'),
            t('table.potSizeMax'),
            t('specificationTable.cartonWeight'),
            t('specificationTable.plasticWeight'),
            t('logisticalInfoTable.onPremiseDeliveryAmount'),
            t('priceInfoTable.deposit'),
            t('priceInfoTable.rentOutSupply'),
            t('priceInfoTable.rentRetSupply'),
            t('priceInfoTable.issuePriceSupply'),
            t('priceInfoTable.rentSubSupply'),
            t('priceInfoTable.rentCustomer'),
            t('priceInfoTable.transferPriceSupply'),
            t('priceInfoTable.transferPriceCustomer'),
            t('priceInfoTable.kickbackDiscount'),
            t('typeLabel'),
            t('productLabel'),
            t('table.amountOfHoles'),
            t('excelExport.fustHolesMax'),
            t('excelExport.fustHolesMin')
        ]);
    };

    const AlternativeContent = () => {
        if (fustListError) {
            return (
                <EmptyState
                    graphic={<EmptyStateIcon />}
                    header={t('errorHeader')}
                    subheader={t('errorBody')} />
            );
        } else {
            return (
                <LoadingIconText
                    loadingText={t('loadingText')}
                    positionCenter />
            );
        }
    }

    return (
        <>
            <ReCAPTCHAComponent reCaptchaRef={reCaptchaRef} />
            <RfhTheme>
                <div style={{ backgroundColor: '#ededed', padding: 16, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 16 }}>
                        <TextField
                            fieldColor='light'
                            id='searchField'
                            placeholder={t('searchField.placeholder')}
                            onChange={(e) => { setGeneralSearchField(e.target.value) }}
                            style={{ minWidth: '50%' }}
                            value={generalSearchField} />
                        <div >
                            <RfhButton
                                onClick={() => { handleExportExcelClick() }}
                                variant='block--contained'
                                style={{ borderRadius: 50, marginRight: 8, marginTop: 4 }}>
                                Excel Export
                            </RfhButton>
                            <RfhButton
                                onClick={() => { handleExportCsvClick() }}
                                variant='block--contained'
                                style={{ borderRadius: 50, marginRight: 8, marginTop: 4 }}>
                                CSV Export
                            </RfhButton>
                            <RfhButton
                                onClick={() => { ClearSearchInputs() }}
                                variant='block--contained'
                                style={{ borderRadius: 50, marginTop: 4 }}>
                                {t('clearButton')}
                            </RfhButton>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingBottom: 16 }}>
                        <RfhTypography variant='h5' style={{ flex: 1 }}>
                            {t('productLabel')}:
                        </RfhTypography>
                        <div style={{ flex: 1 }}>
                            <div>
                                <input id='productRadioSelection1' type='radio' checked={productRadioButton === ProductSelectionEnum.Alle} name='productRadio'
                                    onChange={() => { setProductRadioButton(ProductSelectionEnum.Alle) }} /> {t('productRadioSelection.all')}
                            </div>
                            <div>
                                <input id='productRadioSelection2' type='radio' checked={productRadioButton === ProductSelectionEnum.Snijbloemen} name='productRadio'
                                    onChange={() => { setProductRadioButton(ProductSelectionEnum.Snijbloemen) }} /> {t('productRadioSelection.cutFlowers')}
                            </div>
                            <div>
                                <input id='productRadioSelection3' type='radio' checked={productRadioButton === ProductSelectionEnum.Potplanten} name='productRadio'
                                    onChange={() => { setProductRadioButton(ProductSelectionEnum.Potplanten) }} /> {t('productRadioSelection.potPlants')}
                            </div>
                            <div>
                                <input id='productRadioSelection4' type='radio' checked={productRadioButton === ProductSelectionEnum.Diversen} name='productRadio'
                                    onChange={() => { setProductRadioButton(ProductSelectionEnum.Diversen) }} /> {t('productRadioSelection.other')}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <RfhTypography variant='h5' style={{ flex: 1 }}>
                            {t('useLabel')}:
                        </RfhTypography>
                        <div style={{ flex: 1 }}>
                            <div>
                                <input id='useRadio1' type='radio' checked={usageRadioButton === UsageSelectionEnum.NVT} name='useRadio'
                                    onChange={() => { setUsageRadioButton(UsageSelectionEnum.NVT) }} /> {t('useRadioSelection.nvt')}
                            </div>
                            <div>
                                <input id='useRadio2' type='radio' checked={usageRadioButton === UsageSelectionEnum.Meermalig} name='useRadio'
                                    onChange={() => { setUsageRadioButton(UsageSelectionEnum.Meermalig) }} /> {t('useRadioSelection.reusable')}
                            </div>
                            <div>
                                <input id='useRadio3' type='radio' checked={usageRadioButton === UsageSelectionEnum.Eenmalig} name='useRadio'
                                    onChange={() => { setUsageRadioButton(UsageSelectionEnum.Eenmalig) }} /> {t('useRadioSelection.singleUse')}
                            </div>
                            <div>
                                <input id='useRadio4' type='radio' checked={usageRadioButton === UsageSelectionEnum.Beiden} name='useRadio'
                                    onChange={() => { setUsageRadioButton(UsageSelectionEnum.Beiden) }} /> {t('useRadioSelection.both')}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingBottom: 8 }}>
                        <RfhTypography variant='h5' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 8, flex: 1 }}>
                            {t('typeLabel')}:
                        </RfhTypography>
                        <div style={{ flex: 1 }}>
                            <RfhSelect
                                items={fustTypeList}
                                onSelectItemClick={(_event, item) => {
                                    if (item === fustTypeList[0]) {
                                        setSelectedFustType('');
                                    } else {
                                        setSelectedFustType(item.label);
                                    }
                                }}
                                placeholder={selectedFustType ? selectedFustType : t('typePlaceholder')}
                                isClearable={false}
                                disabled={fustTypeError}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingBottom: 8 }}>
                        <RfhTypography variant='h5' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>
                            {t('potSizeLabel')}:
                        </RfhTypography>
                        <TextField
                            fieldColor='light'
                            id='fustSizeTextField'
                            onChange={(e) => { setFustSizeSearchField(e.target.value) }}
                            value={fustSizeTextField}
                            style={{ flex: 1 }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingBottom: 8 }}>
                        <RfhTypography variant='h5' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>
                            {t('amountOfHolesLabel')}:
                        </RfhTypography>
                        <TextField
                            fieldColor='light'
                            id='amountOfHolesTextField'
                            onChange={(e) => { setAmountOfHolesSearchField(e.target.value) }}
                            value={amountOfHolesSearchField}
                            style={{ flex: 1 }} />
                    </div>
                </div>
                {fustList && fustListLoaded ?
                    <div style={{ overflowX: 'auto' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th><RfhTypography variant={tableDataLabelVariant}>{t('table.fustCode')}</RfhTypography></th>
                                    <th><RfhTypography variant={tableDataLabelVariant}>{t('table.description')}</RfhTypography></th>
                                    <th><RfhTypography variant={tableDataLabelVariant}>{t('table.length')}</RfhTypography></th>
                                    <th><RfhTypography variant={tableDataLabelVariant}>{t('table.width')}</RfhTypography></th>
                                    <th><RfhTypography variant={tableDataLabelVariant}>{t('table.height')}</RfhTypography></th>
                                    <th><RfhTypography variant={tableDataLabelVariant}>{t('table.use')}</RfhTypography></th>
                                    <th><RfhTypography variant={tableDataLabelVariant}>{t('table.amountOfHoles')}</RfhTypography></th>
                                    <th><RfhTypography variant={tableDataLabelVariant}>{t('table.potSizeMin')}</RfhTypography></th>
                                    <th><RfhTypography variant={tableDataLabelVariant}>{t('table.potSizeMax')}</RfhTypography></th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>(mm)</th>
                                    <th>(mm)</th>
                                    <th>(mm)</th>
                                    <th></th>
                                    <th></th>
                                    <th>(mm)</th>
                                    <th>(mm)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderRows(fustList)}
                            </tbody>
                        </table>
                    </div> : <AlternativeContent />}
            </RfhTheme>
        </>
    )
}